import React, { useContext } from 'react';
import { MDBNavbar } from 'mdb-react-ui-kit';
import { ThemeContext } from './Theme'
import { NavbarContent } from './Navbar'

import Logo from '../images/home.png';

function CreateNavbar(props) {

    const { darkMode } = useContext(ThemeContext);

    return (
        darkMode ? <MDBNavbar expand='lg' dark bgColor='dark' sticky>{props.children}</MDBNavbar> : <MDBNavbar expand='lg' light bgColor='light' sticky>{props.children}</MDBNavbar>
    );
}

function NavbarWithHeader(props) {
    const { french } = useContext(ThemeContext);

    return (
        <div>
            <header>
                <CreateNavbar>
                    <NavbarContent />
                </CreateNavbar>

                <div id='header' className='p-5 text-center bg-image' style={{ height: 400 }}>
                    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div className='text-white'>
                                <img id='logo' src={Logo} style={{ height: '52px', width: '52px' }} className='img-fluid mb-3' alt='...' />
                                <h1 className='mb-3'>{!french ? "Guemontet House" : "La Maison Guemontet"}</h1>
                                <h5 className='mb-4'>{!french ? "Experience idylic central France with our affordable holiday let" : "Découvrez la France centrale idyllique avec notre location de vacances abordable."}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

function App() {
    return (
        <NavbarWithHeader />
    );
}

export default App;
