import React, { useState } from 'react';

const FadeInImage = ({ style, src, alt, title, clickHandler }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <img
        style={style}
        src={src}
        alt={alt}
        title={title}
        className={`fade-in-component ${loaded ? 'loaded' : ''}`}
        onLoad={handleImageLoad}
        onClick={clickHandler}
    />
  );
};

export default FadeInImage;