import React, { useState, useContext } from 'react';
import { BackgroundImage } from "react-image-and-background-image-fade";
import axios from 'axios';

import { MDBInput, MDBRipple, MDBCardImage, MDBCardTitle, MDBTypography, MDBTooltip, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard, MDBCardFooter } from "mdb-react-ui-kit";

import { Theme, ThemeContext } from "./components/Theme";
import { Navbar } from "./components/Navbar";
import Footer from "./components/Footer";

import LoginIcon from './images/login.png';
import HouseFront from "./images/house_front.jpg"

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./App.css";

function LoginForm(props) {
  const { darkMode, french } = useContext(ThemeContext);

  const [loginSucceded, setLoginSucceded] = useState(undefined);
  
  const [formValue, setFormValue] = useState({
    referrer: "https://guemontet.house",
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    axios({
      method: "POST",
      url: "https://auth.tombrown.io/api/login",
      data: formValue,
    })
      .then((response) => {
        if (response.status === 200) {
          resetForm(true);
        } else if (response.data.status === 401) {
          resetForm(false);
        }
      })
      .catch(function (error) {
        resetForm(false);
      });
  };

  const resetForm = (success) => {
    setLoginSucceded((loginSucceded) => success);
    if (success) {
      props.onLogin(success);
    } else {
      setTimeout(function () {
        setLoginSucceded((loginSucceded) => undefined);
      }, 3000);
      setFormValue({
        ...formValue,
        password: "",
      });
    }
  };

  return loginSucceded === undefined ?
    <MDBCard style={darkMode ? { minWidth: "12em", width: "30%", maxWidth: "60rem", backgroundColor: "rgba(0, 0, 0, 0.9)" } : { minWidth: "12em", width: "30%", maxWidth: "60rem", backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
      <MDBRipple className="bg-image hover-overlay shadow-1-strong rounded" rippleTag="div" rippleColor="light">
        <MDBCardImage className="img-fluid card-img-top profile-image" src={HouseFront} alt="A house somewhere in France" />
        <a href="#!"><div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.2)" }}></div></a>
      </MDBRipple>
      <form className='' onSubmit={handleSubmit} method='POST'>
        <MDBCardBody cascade>
          <div className='mx-2'>
            <MDBCardTitle className={darkMode ? 'text-light h1 pb-3' : 'h1 pb-3'}><img src={LoginIcon} style={{ height: '45px', width: '45px' }} className='img-fluid' alt='...' />{' '}{french === false ? ("Login") : ("Se Connecter")}</MDBCardTitle>
            {darkMode ? <MDBInput value={formValue.password} name='password' onChange={onChange} className='mt-3' label={french === false ? 'Password' : 'Mot De Passe'}  id='passwordInput' type='password' required contrast /> : <MDBInput value={formValue.password} name='password' onChange={onChange} className='mt-3' label={french === false ? 'Password' : 'Mot De Passe'}  id='passwordInput' type='password' required />}
          </div>
        </MDBCardBody>
        <MDBCardFooter className='mt-1 text-center mb-3'>
          <MDBTooltip wrapperProps={{ type: 'submit', color: darkMode ? 'dark' : 'primary' }} placement='bottom' title={french === false ? 'Submit' : 'Soumettre'}>
            <MDBIcon fas icon='fingerprint' size='2x' />
          </MDBTooltip>
        </MDBCardFooter>
      </form>
    </MDBCard>
    : loginSucceded === true ? <MDBTypography note noteColor='success'>{french == false ? "Login Succeeded!" : "Connexion réussie!"}</MDBTypography> :
      <MDBTypography note noteColor='danger'>{french == false ? "Incorrect password!" : "Mot De Passe Incorrect!"}</MDBTypography>
};

function LoginPage(props) {
  const { background } = useContext(ThemeContext);
  return (
    <div>
      <BackgroundImage src={background != undefined ? background.urls.full : ""} className="App">
        <Navbar />
        <MDBContainer fluid className="app_content">
          <MDBRow className="pb-5 align-items-center app_content">
            <MDBCol className="pb-5 offset-lg-1 offset-xl-2" xs="12" lg="10" xl="8">
              <MDBCol className="d-flex justify-content-center align-items-center">
                <LoginForm onLogin={props.onLogin} />
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </BackgroundImage>
    </div>
  );
}

function Login(props) {
  return (
    <Theme themeContext={ThemeContext}>
        <LoginPage onLogin={props.onLogin} />
    </Theme>
  );
}

export default Login;