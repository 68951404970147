import React, { useState, useContext, useEffect } from "react";
import { MDBTooltip, MDBIcon } from "mdb-react-ui-kit";
import { createApi } from 'unsplash-js';

import { Tracking } from "./Tracking";
import France from '../images/france.png';

const ThemeContext = React.createContext();
const unsplash = createApi({ accessKey: 'SDcLLuGmusV5_1aGlZbWwkvceWID5JiRz1x24qB3jHA' });

async function getRandomPhotoFromUnsplash(collectionIds) {
  try {
    const result = await unsplash.photos.getRandom({
      featured: true,
      collectionIds: collectionIds,
      count: 1,
    });

    if (result.errors) {
      console.error('Error occurred:', result.errors[0]);
      return null;
    } else {
      const photo = result.response[0];
      return photo;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
}

function DarkModeToggle() {
  const { darkMode, french, toggleDarkMode } = useContext(ThemeContext);
  let icon = darkMode ? (<MDBIcon className="text-warning" far icon="lightbulb" size="2x" />)
    : (<MDBIcon className="text-warning" fas icon="lightbulb" size="2x" />);

  return (
    <div><MDBTooltip wrapperProps={{ className: "mx-2", onClick: toggleDarkMode, color: "transparent" }} placement="bottom" title={!french ? "Toggle Dark Mode" : "Activer Le Mode Sombre"}>{icon}</MDBTooltip></div>
  );
}

function FrenchToggle() {
  const { french, toggleFrench } = useContext(ThemeContext);
  let icon = <MDBIcon className="text-warning" size="2x"><img style={{ width: '100%', height: '100%'}} src={France} /></MDBIcon>;

  return (
    <div><MDBTooltip wrapperProps={{ className: "mx-2", onClick: toggleFrench, color: "transparent" }} placement="bottom" title={!french ? "Toggle French Language" : "Changer La Langue En Anglais"}>{icon}</MDBTooltip></div>
  );
}

function Theme(props) {
  var currentHour24 = new Date().getHours();

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  const toggleFrench = () => {
    setFrench((prevDarkMode) => !prevDarkMode);
  };

  const [darkMode, setDarkMode] = useState(
    currentHour24 >= 21 || currentHour24 <= 8
  );

  const [french, setFrench] = useState(false);

  const [background, setBackground] = useState(null);

  useEffect(() => {
    const setBackgroundPhoto = async () => {
      const query = darkMode ? ['401922', '3468132', '41020635'] : ['mCxOIZM8G8A', '256524', '1525589', '361687', '148982'];
      const photo = await getRandomPhotoFromUnsplash(query);
      setBackground(photo);
    };

    setBackgroundPhoto();
  }, [darkMode]);

  return (
    <div>
      <ThemeContext.Provider value={{ darkMode, toggleDarkMode, french, toggleFrench, background, setBackground }}>
        <Tracking themeContext={ThemeContext} />
          {props.children}
      </ThemeContext.Provider>
    </div>
  );
}

export { Theme, DarkModeToggle, FrenchToggle, ThemeContext };
