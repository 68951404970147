import React, { useState, useContext } from 'react';
import axios from 'axios'
import { MDBInput, MDBTypography, MDBTooltip, MDBIcon, MDBCardTitle, MDBCardText, MDBCardBody, MDBCard, MDBCardFooter } from 'mdb-react-ui-kit';
import { ThemeContext } from './Theme'
import Email from '../images/email.png';

function ContactForm(props) {

  const { darkMode, french } = useContext(
    ThemeContext
  );

  const [formValue, setFormValue] = useState({
    referrer: 'guemontet.house',
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [sendSucceeded, setSucceded] = useState(undefined);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    axios({
      method: 'POST',
      url: props.sendUrl,
      data: formValue
    })
      .then((response) => {
        if (response.data.status === 'success') {
          resetForm(true);
        } else if (response.data.status === 'fail') {
          resetForm(false);
        }
      })
      .catch(function (error) {
        resetForm(false);
      });
  }

  const resetForm = (success) => {
    setSucceded(sendSucceeded => success);
    setTimeout(function () { setSucceded(sendSucceeded => undefined); }, 3000);
    setFormValue({ ...formValue, name: '', email: '', subject: '', message: '' });
  };

  return sendSucceeded === undefined ?
    <MDBCard style={darkMode ? { width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)' } : { width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
      <form className='' onSubmit={handleSubmit} method='POST'>
        <MDBCardBody cascade="true">
          <div className='mx-2'>
            <MDBCardTitle className={darkMode ? 'text-light h2 mb-4 text-center' : 'h2 mb-4 text-center'}><img src={Email} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' />{' '}{!french ? "Contact Us" : "Contactez-nous"}</MDBCardTitle>
            <MDBCardText className={darkMode ? 'mt-4 mx-2 text-light' : 'mt-4 mx-2'}>
              <MDBTypography className='lead mb-0'>{!french ? "To enquire about our availability and prices, please contact us using the form below." : "Pour vous renseigner sur nos disponibilités et tarifs, veuillez nous contacter en utilisant le formulaire ci-dessous."}</MDBTypography>
            </MDBCardText>
            {darkMode ? <MDBInput value={formValue.name} name='name' onChange={onChange} className='mt-3' label='Name' id='nameInput' type='text' required contrast /> : <MDBInput value={formValue.name} name='name' onChange={onChange} className='mt-3' label='Name' id='nameInput' type='text' required />}
            {darkMode ? <MDBInput value={formValue.email} name='email' onChange={onChange} className='mt-3' label='Email Address' id='emailInput' type='email' required contrast /> : <MDBInput value={formValue.email} name='email' onChange={onChange} className='mt-3' label='Email Address' id='emailInput' type='email' required />}
            {darkMode ? <MDBInput value={formValue.subject} name='subject' onChange={onChange} className='mt-3' label='Subject' id='subjectInput' type='text' required contrast /> : <MDBInput value={formValue.subject} name='subject' onChange={onChange} className='mt-3' label='Subject' id='subjectInput' type='text' required />}
            {darkMode ? <MDBInput value={formValue.message} name='message' onChange={onChange} className='mt-3' label='Message' id='messageInput' textarea rows={6} required contrast /> : <MDBInput value={formValue.message} name='message' onChange={onChange} className='mt-3' label='Message' id='messageInput' textarea rows={6} required />}
          </div>
        </MDBCardBody>
        <MDBCardFooter className='mt-1 text-center'>
          <MDBTooltip wrapperProps={{ type: 'submit', color: darkMode ? 'dark' : 'primary' }} placement='bottom' title={!french ? "Send" : "Envoyez"}>
            <MDBIcon fas icon='paper-plane' size='2x' />
          </MDBTooltip>
        </MDBCardFooter>
      </form>
    </MDBCard>
    : sendSucceeded === true ? <MDBTypography note noteColor='success'>Thank you for your message!</MDBTypography> :
      <MDBTypography note noteColor='danger'>Message failed to send - please hmu on LinkedIn</MDBTypography>
    ;
}

function App() {
  var sendUrl = "https://mail.tombrown.io/send";
  if (process.env.NODE_ENV !== "production") {
    sendUrl = "http://localhost:3002/send";
  }

  return (
    <ContactForm sendUrl={sendUrl} />
  );
}

export default App;
