import React, { useState, useEffect, useRef, useContext } from 'react';
import { MDBTypography, MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard, MDBCardTitle, MDBCardText } from "mdb-react-ui-kit";
import Slider from 'react-slick';
import { helix } from 'ldrs'
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import Login from './Login';
import { Theme, ThemeContext } from "./components/Theme";
import NavbarWithHeader from "./components/NavbarWithHeader";
import Contact from "./components/Contact"
import Footer from "./components/Footer";
import FadeInImage from './components/FadeInImage';

import HeroVideo from "./images/hero.mp4";
import HomeIcon from './images/home.png';
import HouseFront from "./images/house_front.jpg"
import HouseBack from "./images/house_back.jpg"
import Garden1 from "./images/garden_1.jpg"
import Driveway from "./images/driveway.jpg";
import LivingRoom1 from "./images/living_room_1.jpg"
import LivingRoom2 from "./images/living_room_2.jpg";
import Master1 from "./images/master_1.jpg"
import Master2 from "./images/master_2.jpg";
import Dorm from "./images/dorm.jpg";
import Attic from "./images/attic.jpg";
import Lacelle1 from "./images/lacelle_1.jpg";
import Lake1 from "./images/lake_1.jpg";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import './App.css';

function ThemedSlider(props, width, customClass) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const { darkMode } = useContext(
    ThemeContext
  );

  return (
    {
      ...darkMode ?
        <MDBRow id='slider-light' className={`mb-4 d-flex justify-content-center ${props.customClass}`}>
          <Slider style={{ width: props.width }}  {...settings}>
            {props.children}
          </Slider>
        </MDBRow>
        :
        <MDBRow id='slider-dark' className={`mb-4 d-flex justify-content-center ${props.customClass}`}>
          <Slider style={{ width: props.width }}  {...settings}>
            {props.children}
          </Slider>
        </MDBRow>
    }
  );
}

function Home() {
  const { darkMode, french } = useContext(
    ThemeContext
  );

  const [open, setOpen] = useState(false);

  return (
    <div>
      <NavbarWithHeader />
        <MDBContainer fluid className='app_content'>
          <MDBRow className='g-3 pb-3 pt-3'>
          <MDBCol xl='8'>
            <MDBCard style={darkMode ? { width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)' } : { width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
              <MDBCardBody>
                <MDBCardTitle className={darkMode ? 'text-light h2 mx-2 text-center' : 'h2 mx-2 text-center'}><img src={HomeIcon} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' />{' '}{!french ? "The House" : "La Maison"}</MDBCardTitle>
                <MDBCardText className={darkMode ? 'mt-4 mx-2 text-light' : 'mt-4 mx-2'}>
                  {!french ?
                    <MDBTypography className='lead mb-0'>Discover the hidden gem of Guémontet, a serene village nestled in the heart of France's picturesque countryside. This tranquil haven offers a perfect blend of rustic charm and modern comfort. Imagine waking up to the melody of birdsong and panoramic views of rolling hills and lush meadows.
                    Guémontet is steeped in rich cultural heritage. Explore nearby medieval towns, ancient churches, and vibrant local markets filled with fresh produce and artisanal crafts. Indulge in the region's culinary delights, from locally made cheeses and wines to gourmet meals at charming bistros.
                    The house is a large rustic cottage that comfortably sleeps 8 people, but could sleep as many as 12. There are 3 double bedrooms, and a dorm room with 4 single beds.<br /><br/>
                    The house has a large garden overlooking the surrounding hills and the local lake, where you can find a restaurant / bar. The Garden is the ideal place to host a family BBQ, to relax in the sun, or for your dog to enjoy. The hamlet of Guémontet is located about 10 minutes drive away from the medieval village of La Celle Dunoise, where you can find the local Irish Bar - a real favourite of ours and very popular with our guests.
                    <br /><br />
                    </MDBTypography> : 
                    <MDBTypography className='lead mb-0'>Découvrez le joyau caché de Guémontet, un village serein niché au cœur de la pittoresque campagne française. Ce havre de paix offre un parfait mélange de charme rustique et de confort moderne. Imaginez-vous réveiller au chant des oiseaux avec des vues panoramiques sur des collines ondulantes et des prairies verdoyantes.
                    Guémontet est imprégné d'un riche patrimoine culturel. Explorez les villes médiévales voisines, les anciennes églises, et les marchés locaux animés remplis de produits frais et d'artisanat. Régalez-vous des délices culinaires de la région, des fromages et vins locaux aux repas gastronomiques dans les bistrots charmants.
                    La maison est un grand cottage rustique qui peut accueillir confortablement 8 personnes, mais peut en héberger jusqu'à 12. Il y a 3 chambres doubles et un dortoir avec 4 lits simples.<br /><br />
                    La maison dispose d'un grand jardin surplombant les collines environnantes et le lac local, où vous trouverez un restaurant/bar. Le jardin est l'endroit idéal pour organiser un barbecue familial, se détendre au soleil, ou pour que votre chien en profite. Le hameau de Guémontet se trouve à environ 10 minutes en voiture du village médiéval de La Celle Dunoise, où vous pouvez trouver le bar irlandais local - un de nos favoris et très populaire auprès de nos invités.
                    <br /><br />
                    </MDBTypography>
                  }
                  <Lightbox
                    plugins={[Thumbnails, Captions, Fullscreen, Slideshow, Zoom]}
                    open={open}
                    close={() => setOpen(false)}
                    slides={[
                      { 
                        src: HouseFront,
                        title: "Front of the House",
                        description: "The rustic exterior of the house with french shutters.",
                      },
                      { 
                        src: HouseBack,
                        title: "Back of the House",
                        description: "The back of the house including an open-air porch.",
                      },
                      { 
                        src: Garden1,
                        title: "The Garden",
                        description: "We have a sizable garden with ample room for pets / children to play or to host a barbeque.",
                      },
                      { 
                        src: Driveway,
                        title: "The Driveway",
                        description: "The driveway is suitable for up to 3 cars.",
                      },
                      { 
                        src: LivingRoom1,
                        title: "Living Room",
                        description: "The living room is a cozy and rustic place to relax, with a working fireplace to provide warmth in the winter.",
                      },
                      { 
                        src: LivingRoom2,
                        title: "Living Room",
                        description: "The living room is a cozy and rustic place to relax, with a working fireplace to provide warmth in the winter.",
                      },
                      { 
                        src: Master1,
                        title: "Master Bedroom 1",
                        description: "The first master bedroom containing a sink.",
                      },
                      { 
                        src: Master2,
                        title: "Master Bedroom 2",
                        description: "The second master bedroom.",
                      },
                      { 
                        src: Dorm,
                        title: "Dorm Room",
                        description: "A dorm room that is suitable for 4 people.",
                      },
                      { 
                        src: Attic,
                        title: "Attic Room",
                        description: "The attic room is flooded with natural light, there is a double and a single bed.",
                      },
                      { 
                        src: Lacelle1,
                        title: "La Celle Dunoise",
                        description: "The beautiful medieval village of La Celle Dunoise containing historic architecture and an Irish pub / restaurant.",
                      },
                      { 
                        src: Lake1,
                        title: "The Local Lake",
                        description: "The local lake is only 10 minute drive from the house and has a bar / restaurant and pedalos for hire."
                      },
                    ]}
                  />
                  <ThemedSlider width={'50%'} customClass={'gallery'}>
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={HouseFront} title='House Exterior' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={HouseBack} title='House Back Exterior' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Garden1} title='Garden' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Driveway} title='Driveway' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={LivingRoom1} title='Living Room 1' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={LivingRoom2} title='Living Room 2' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Master1} title='Master Bedroom 1' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Master2} title='Master Bedroom 2' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Dorm} title='Dorm Room' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Attic} title='Attic Room' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Lacelle1} title='Lacelle Dunoise' clickHandler={() => setOpen(true)} />
                      <FadeInImage style={{ width: '100%', height: '100%'}} src={Lake1} title='Lake' clickHandler={() => setOpen(true)} />
                  </ThemedSlider>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
            <MDBCol xl='4'>
              <Contact />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      <Footer />
    </div>
  );
}

const App = () => {
  helix.register();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  const handleCanPlayThrough = () => {
    setLoading(false);
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.playbackRate = 3;
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, []);

  return (
    <div className="App">
      {isLoggedIn ? (
        <>
          {loading && (
            <div className="centered-container">
              <l-helix size="60" speed="2.5" color="black"></l-helix>
            </div>
          )}
          <video
            ref={videoRef}
            onEnded={handleVideoEnd}
            onCanPlayThrough={handleCanPlayThrough}
            preload="auto"
            muted
            id="hero_video"
            style={{ display: loading ? 'none' : 'block' }}
          >
            <source src={HeroVideo} type="video/mp4" />
          </video>
          {videoEnded && (
            <div className="hero-animation">
              <Theme>
                <Home themeContext={ThemeContext} />
              </Theme>
            </div>
          )}
        </>
      ) : (
        <Login onLogin={setIsLoggedIn} />
      )}
    </div>
  );
};

export default App;
